var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.stars == 0
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" })
      ])
    : this.stars == 1
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star-half-o" })
      ])
    : this.stars == 2
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" })
      ])
    : this.stars == 3
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star-half-o" })
      ])
    : this.stars == 4
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" })
      ])
    : this.stars == 5
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star-half-o" })
      ])
    : this.stars == 6
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" })
      ])
    : this.stars == 7
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star-half-o" })
      ])
    : this.stars == 8
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" })
      ])
    : this.stars == 9
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star-half-o" })
      ])
    : this.stars == 10
    ? _c("div", { staticClass: "ratings" }, [
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" }),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-star" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }