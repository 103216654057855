<template>
    <div class="ratings"
        v-if="this.stars == 0">
        <i class="fa fa-star"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 1">
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 2">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 3">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 4">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 5">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 6">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 7">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 8">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 9">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-o"></i>
    </div>
    <div class="ratings"
        v-else-if="this.stars == 10">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i></i>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-display',
    	props: {
    		stars: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5.5
    		};
    	},
    	computed: {
    		fullstars: function() {
    			/*	var stars = parseInt(this.stars);
    				if (stars == 0) {
    					return 1;
    				} else if (stars == 1) {
    					return 1.5;
    				} else if (stars == 2) {
    					return 2;
    				} else if (stars == 3) {
    					return 2.5;
    				} else if (stars == 4) {
    					return 3;
    				} else if (stars == 5) {
    					return 3.5;
    				} else if (stars == 6) {
    					return 4;
    				} else if (stars == 7) {
    					console.log('Stars7');
    					return 4.5;
    				} else if (stars == 8) {
    					return 5;
    				} else if (stars == 9) {
    					return 5.5;
    				}
    				//return null */
    		},
    		halfstar: function() {
    			/*	var remaingStar = this.stars - parseInt(this.stars);
    				return (remaingStar > 0.49 ? true : false); */
    		},
    		emptystars: function() {
    			if (this.halfstar) {
    				return this.totalStars - (parseInt(this.stars) || 0) - 1;
    			} else {
    				return this.totalStars - (parseInt(this.stars) || 0);
    			}
    		}
    	}
    };
</script>